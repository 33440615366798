export default defineNuxtRouteMiddleware((to, from) => {
    const auth = useAuthStore();

    if (!auth.isLogged) {
        if (from.path !== to.path) {
            // it's not a first page load
            auth.openLoginNotice();
        }

        return navigateTo("/");
    }
});
